import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PhotoGallery from "../components/PhotGallery/PhotoGallery"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import BackgroundImage from "gatsby-background-image"
import "./about.css"
import SEO from "../components/SEO/SEO"

function AboutPage() {
  return (
    <>
      <Navbar />
      <SEO
        title="About "
        description="Wedding Thapas provides Photography Cinematography/ Wedding
            Films of high quality in Kathmandu, Nepal. We are one of the top
            wedding event photography company in Nepal, providing the best
            quality of photograph. Wedding Thapas photography team is committed
            to provide you with all kinds of services."
      ></SEO>
      <div className="about">
        <div className="about-container" style={{ marginTop: "4px" }}>
          <h2>About Us</h2>
          <p>
            Wedding Thapas provides Photography &amp; Cinematography/ Wedding
            Films of high quality in Kathmandu, Nepal. We are one of the top
            wedding event photography company in Nepal, providing the best
            quality of photograph. Wedding Thapas photography team is committed
            to provide you with all kinds of services.
          </p>

          <h2>Why us?</h2>
          <p>
            Do you want to show your story about your wedding in sensational
            photographic images? Looking for a wedding photographer who is an
            expert in making beautiful wedding images? Wedding Thapas offers
            exquisite and beautiful wedding photography to clients in Nepal
            &amp; Australia. Emotional and near moments can be captured forever
            in time with wedding photography and preserved for decades to come.
            Special photographs of the wedding are a luxury and something to
            preserve and cherish for ever. So just relax and enjoy the special
            wedding events, as we create beautiful and amazingly beautiful
            images.
          </p>
          <p>
            Our photography services for your wedding are available at extremely
            competitive rates and we offer a fantastic value for money. We take
            care when you rely on us to provide you with services that will make
            your wedding memories special. If you need high quality images where
            quality is not compromised, then we are the best choice. Our
            photographers are well trained to take the best images and we are
            industry experts. So, don&#39;t wait any longer and tell us about
            the basic criteria and expectations for your wedding photograph.
            We’re going to do our hardest to represent you. For any guidance on
            photography services we give you can contact to our friendly
            workers. Please feel free to contact us for more information on
            Wedding Thapas or visit our office
          </p>
          <ul className="sub-about">
            <li>
              <p>
                <div>Kageswori Manohara-09, KTM, Nepal</div>
                <div>9841419161</div>
              </p>
            </li>
            <li>
              <p>
                <div>Melbourne and Sydney, Australia</div>
                <div>0413666273</div>
              </p>
            </li>
          </ul>
          <p>
            Build Unforgettable Memories with Qualified Photography Services Our
            premium wedding photography services are extremely beneficial:
          </p>
          <ul className="sub-about">
            <li>
              <h4>Build A Special Style Pictures</h4>
              <p>
                The theme is a critical component of wedding photography.
                Wedding images are beautiful and appealing. Professional
                photographers apply artistic skills in creating images that
                enhance your personality.
              </p>
            </li>
            <li>
              <h4>Use of New Equipment</h4>
              <p>
                We have the latest devices and advanced equipment to meet all
                your special requirements in photography.
              </p>
            </li>
            <li>
              <h4>Skilled Staff</h4>
              <p>
                A skilled photographer can apply expert knowledge to ensure high
                quality photography of your wedding activities.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default AboutPage
